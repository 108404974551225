import { breakpointValues } from '@finn/atoms/Theme';
import { ResponsiveCosmicImage, ResponsiveImageSizes } from '@finn/ui-cosmic';
import { getCloudinaryImgUrl } from '@finn/ui-utils';

export type OptimizedImage = {
  url: string;
  width: number;
};

const getHeroImageWidth = (size: ResponsiveImageSizes) => {
  if (size === ResponsiveImageSizes.LARGE) {
    return breakpointValues.xl;
  }
  if (size === ResponsiveImageSizes.MEDIUM) {
    return breakpointValues.md;
  }

  if (size === ResponsiveImageSizes.SMALL) {
    return breakpointValues.sm;
  }

  return breakpointValues.mobile; // min screen size from our design in Figma
};

export const getOptimizedHeroImage = (
  cosmicImage: ResponsiveCosmicImage,
  size: ResponsiveImageSizes
): OptimizedImage => {
  const image = cosmicImage.metadata;
  const width = getHeroImageWidth(size);
  const url = getCloudinaryImgUrl(image[size].url, {
    w: width,
    dpr: 2, // TODO-REBRANDING: Auto detect relying on device/network
  });

  return { url, width };
};
