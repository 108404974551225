import { Container } from '@finn/atoms';
import { breakpointValues } from '@finn/atoms/Theme';
import { cn } from '@finn/ui-utils';
import { Box, makeStyles } from '@material-ui/core';
import Head from 'next/head';
import React from 'react';

import { HeroCosmicMetadata, ResponsiveImageSizes } from '~/types/UIModules';

import HeroContent from './HeroContent';
import { getOptimizedHeroImage, OptimizedImage } from './utils';

type StyledProps = {
  image: {
    sm: OptimizedImage;
    md: OptimizedImage;
    lg: OptimizedImage;
  };
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: breakpointValues.max,
    margin: `${theme.spacing(0)} auto`,
    backgroundSize: '700% 100%',
    animation: '3s $shine linear infinite',
    marginBottom: theme.spacing(-3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(-5),
    },
  },
  '@keyframes shine': {
    to: { backgroundPositionX: '-200%' },
  },
  wrapperImage: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '480px',
    backgroundImage: (props: StyledProps) => `url(${props.image?.sm?.url})`,
    [theme.breakpoints.up('sm')]: {
      backgroundImage: (props: StyledProps) => `url(${props.image?.md?.url})`,
      height: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: (props: StyledProps) => `url(${props.image?.lg?.url})`,
    },
  },
}));

type Props = {
  data: HeroCosmicMetadata;
};

const PrimaryHero: React.FunctionComponent<Props> = ({ data }) => {
  const { image, ...rest } = data;

  const heroType = rest.level.value;

  const responsiveImage = image && {
    sm: getOptimizedHeroImage(image, ResponsiveImageSizes.SMALL),
    md: getOptimizedHeroImage(image, ResponsiveImageSizes.MEDIUM),
    lg: getOptimizedHeroImage(image, ResponsiveImageSizes.LARGE),
  };

  const classes = useStyles({
    image: responsiveImage,
  });

  return (
    <div data-testid="hero">
      <Head>
        <link
          rel="preload"
          href={responsiveImage.sm.url}
          as="image"
          media={`(max-width: ${responsiveImage.sm.width}px)`}
        />
        <link
          rel="preload"
          href={responsiveImage.md.url}
          as="image"
          media={`(max-width: ${responsiveImage.md.url}px)`}
        />
        <link
          rel="preload"
          href={responsiveImage.lg.url}
          as="image"
          media={`(max-width: ${responsiveImage.lg.url}px)`}
        />
      </Head>
      <Box className={cn(classes.wrapper, 'bg-pewter')}>
        <Box className={classes.wrapperImage}>
          <Container>
            <HeroContent
              title={rest.title}
              subtitle={rest.subtitle}
              heroType={heroType}
              cta={rest.cta}
              secondaryCta={rest.secondary_cta}
            />
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default PrimaryHero;
