import React from 'react';

import { HeroCosmicMetadata, HeroType } from '~/types/UIModules';

import PrimaryHero from './PrimaryHero';
import SecondaryHero from './SecondaryHero';

type Props = {
  data: HeroCosmicMetadata;
};

const PageHero: React.FunctionComponent<Props> = ({ data }) => {
  const heroType = data.level.value;

  const isPrimaryHero = heroType === HeroType.primary;

  if (isPrimaryHero) {
    return <PrimaryHero data-testid="hero" data={data} />;
  } else {
    return <SecondaryHero data-testid="hero" data={data} />;
  }
};

export default PageHero;
