import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import trim from 'lodash/trim';
import React from 'react';
import { useIntl } from 'react-intl';

import { GuideMetaData, HeroType } from '~/types/UIModules';
import SocialSharing from '~/ui-modules/Hero/SocialSharing/SocialSharing';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    whiteSpace: 'pre-wrap',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 0),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(17, 0),
    },
    '&.no-image': {
      paddingBottom: 0,
    },
  },
  textBox: {
    position: 'relative',
  },
  metaBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    padding: theme.spacing(3.5, 0),
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      padding: 0,
    },
  },
  metaBoxItem: {
    flexBasis: '50%',
    padding: theme.spacing(1.5, 0),
    [theme.breakpoints.up('md')]: {
      flexBasis: '100%',
      padding: theme.spacing(0, 0, 3, 3),
    },
  },
  labelWrapper: {
    position: 'absolute',
    top: '-40px',
  },
  labeldiv: {
    display: 'inline-block',
    borderRadius: '2px',
    padding: theme.spacing(1, 1),
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& > *': {
      flexShrink: 0,
    },

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),

      '& > * + *': {
        marginTop: 0,
      },
    },
  },

  titleContainer: {
    marginTop: theme.spacing(2),
    wordBreak: 'break-word',
    maxWidth: 832,
  },

  subtitleContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      maxWidth: 720,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 800,
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

type Props = {
  title: string;
  subtitle: string;
  heroType: HeroType;
  cta?: CTAData;
  secondaryCta?: CTAData;
  guideMetaData?: GuideMetaData;
  label?: string;
  noImage?: boolean;
};

const hasRelevantMetaField = (guideMetaData: GuideMetaData) => {
  return trim(guideMetaData?.date) || trim(guideMetaData?.reading_time);
};

const HeroContent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  heroType,
  cta,
  secondaryCta,
  guideMetaData,
  label,
  noImage,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      data-nativeappid="header-content"
      className={cn(classes.wrapper, { 'no-image': noImage })}
    >
      <div className={classes.textBox}>
        {label ? (
          <div className={classes.labelWrapper}>
            <span
              className={cn(
                classes.labeldiv,
                'body-12-medium border-pearl border border-solid'
              )}
            >
              {label}
            </span>
          </div>
        ) : null}
        <div className={classes.titleContainer}>
          <h1
            className={`mobile-t2-semibold md:web-t2-semibold ${heroType === HeroType.primary ? 'text-white' : 'text-black'}`}
          >
            {parseToHtml(title)}
          </h1>
        </div>

        {subtitle && (
          <div className={classes.subtitleContainer}>
            <p className="body-16-light">{parseToHtml(subtitle)}</p>
          </div>
        )}

        {(cta || secondaryCta) && (
          <div className={classes.buttonWrapper}>
            {cta && <CTA data={cta.metadata} />}
            {secondaryCta && <CTA data={secondaryCta.metadata} />}
          </div>
        )}
      </div>
      {hasRelevantMetaField(guideMetaData) && (
        <div className={classes.metaBox}>
          {guideMetaData.reading_time && (
            <div className={classes.metaBoxItem}>
              <h6 className="global-t6-semibold">
                {intl.formatMessage({
                  id: 'general.guideReadTime',
                })}
              </h6>
              <p className="body-16-light mt-3">{guideMetaData.reading_time}</p>
            </div>
          )}
          {guideMetaData.date && (
            <div className={classes.metaBoxItem}>
              <h6 className="global-t6-semibold">
                {intl.formatMessage({
                  id: 'general.guideDate',
                })}
              </h6>
              <p className="body-16-light mt-3">{guideMetaData.date}</p>
            </div>
          )}
          <div className={cn(classes.metaBoxItem, classes.grow)}>
            <h6 className="global-t6-semibold mb-2">
              {intl.formatMessage({
                id: 'general.guideShare',
              })}
            </h6>
            <SocialSharing scale={0.8} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroContent;
